import { SettingService } from "./settings";

export class FieldValidationRule {
  static isRequired(value) {
    return new Promise((resolve) => {
      if (!value || value.trim() == "") {
        resolve("This field is required");
      }
      resolve(true);
    });
  }

  static max50CharsAllowed(value) {
    return new Promise((resolve) => {
      if (value && value.length > 50) {
        resolve("Max 50 characters allowed.");
      }
      resolve(true);
    });
  }

  static max500CharsAllowed(value) {
    return new Promise((resolve) => {
      if (value && value.length > 50) {
        resolve("Max 50 characters allowed.");
      }
      resolve(true);
    });
  }
}

export class IntegrationSettingsMeta {
  static meta = {
    judgeme: {
      installType: "oauth",
      getInstallLink: (app, component) => {
        // let redirectUri = SettingService.integrationServiceBaseUrl + "/oauth-redirect-hook";
        let redirectUri = `${SettingService.dashboardUrl}/app-market/app-install-hook/judgeme`;
        let state = { cid: component.getAuthToken().companyId, aid: app.id, rdr: redirectUri };
        let judgemeAppClientId = "f6eb39ef8a65456deced04a4add92238e484da1b9c471c5ae1b26e18889a638b";

        state = encodeURIComponent(JSON.stringify(state));
        redirectUri = encodeURIComponent(redirectUri);
        return `https://judge.me/oauth/authorize?client_id=${judgemeAppClientId}&redirect_uri=${redirectUri}&response_type=code&scope=read_shops%20read_reviews%20write_reviews%20read_comments%20write_comments%20read_settings%20write_settings%20read_products&state=${state}`;
      },
      comingsoon: false,
      // fields: [
      //   {
      //     id: "judgeme_api_key",
      //     label: "Judgeme API Key",
      //     type: "text",
      //     placeholder: "Enter Judgeme API Key",
      //     required: true,
      //     validationRules: [FieldValidationRule.isRequired, FieldValidationRule.max50CharsAllowed],
      //   },
      //   {
      //     id: "shop_domain",
      //     label: "Shop Domain",
      //     type: "text",npm
      //     placeholder: "mystore.myshopify.com",
      //     required: true,
      //     validationRules: [FieldValidationRule.isRequired, FieldValidationRule.max500CharsAllowed],
      //   },
      // ],
    },
    fera: {
      installType: "oauth",
      getInstallLink: () => {
        return "https://fera.io";
      },
      comingsoon: true,
    },
    shopquiz: {
      installType: "oauth",
      getInstallLink: () => {
        return "https://fera.io";
      },
      comingsoon: true,
    },
    riddle: {
      installType: "oauth",
      getInstallLink: () => {
        return "https://riddle.com/";
      },
      comingsoon: true,
    },
    facebook: {
      installType: "oauth",
      getInstallLink: (app, component) => {
        let redirectUri = `${SettingService.dashboardUrl}/app-market/app-install-hook/facebook`;

        let state = encodeURIComponent(
          JSON.stringify({
            cid: component.getAuthToken().companyId,
            aid: app.id,
            rdr: redirectUri,
          })
        );
        let facebookAppClientId = "512314313551283";
        let scope = "ads_management";

        return `https://www.facebook.com/v2.8/dialog/oauth?client_id=${facebookAppClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
      },
      comingsoon: true,
    },
    google: {
      installType: "oauth",
      getInstallLink: () => {
        return "https://riddle.com/";
      },
      comingsoon: true,
    },
    shopflo: {
      installType: "reverseIntegration",
      getInstallLink: () => {
        return null;
      },
      comingsoon: true,
    },
    zecpe: {
      installType: "oauth",
      getInstallLink: () => {
        return "https://riddle.com/";
      },
      comingsoon: true,
    },
  };

  static getMeta(integrationName) {
    return this.meta[integrationName];
  }
}
