import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "./assets/tailwind.css";
import router from "./router";

import commonMixin from "./mixins/commonMixin";

createApp(App).use(router).use(store).use(Quasar, quasarUserOptions).mixin(commonMixin).mount("#app");
