export class SettingService {
  static integrationServiceBaseUrl = "http://localhost:5123";
  static settingsServiceBaseUrl = "http://localhost:5000";

  static dashboardUrl = "http://localhost:8080";
}

if (process.env.NODE_ENV === "production") {
  SettingService.integrationServiceBaseUrl = "https://integration-service.growlytics.in";
  SettingService.settingsServiceBaseUrl = "https://appi.growlytics.in";
  SettingService.dashboardUrl = "https://app.growlytics.in";
} else if (process.env.NODE_ENV === "staging") {
  SettingService.integrationServiceBaseUrl = "https://integration-db-api-staging-qpewk.ondigitalocean.app";
}
