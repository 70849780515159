<template>
  <q-layout class="main-layout">
    <q-page-container>
      <q-page>
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "App",
  components: {},
  setup() {
    const store = useStore();
    if (location.pathname !== "/auth") {
      // store.dispatch("fetchCredentials");
      store.dispatch("fetchToken");
    }
    return {};
  },
};
</script>

<style lang="scss">
.main-layout {
  padding: 0px;
  background: #f6f8f9 !important;
  line-height: normal;
  color: #1a1a1a;
  font-family: "Roboto", "Rubik", sans-serif;
  // font-family: "Montserrat", sans-serif;
  letter-spacing: 0.6px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.common-app-header {
  background-color: #ffffff;
  opacity: 0.8;
  background: linear-gradient(135deg, #f4f4f455 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #f4f4f4 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #f4f4f455 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #f4f4f4 25%, #ffffff 25%) 0px 0/ 20px 20px;

  .title {
    color: #002225;
  }
}
</style>
