import session from "./../util/session";
import { SettingService } from "./settings";

export class AppService {
  static async getAppList() {
    console.log(process.env.NODE_ENV);
    return session.get(`${SettingService.integrationServiceBaseUrl}/app`);
  }

  static async getAppDetails(appId) {
    return session.get(`${SettingService.integrationServiceBaseUrl}/app/${appId}`);
  }

  static async installApp(params) {
    return session.post(`${SettingService.integrationServiceBaseUrl}/app/install`, params);
  }

  static async uninstallApp(params) {
    return session.post(`${SettingService.integrationServiceBaseUrl}/app/uninstall`, params);
  }

  static async handleOauthRedirect(params) {
    return session.post(`${SettingService.integrationServiceBaseUrl}/oauth-redirect-hook`, params);
  }
}
