import axios from "axios";
const CSRF_COOKIE_NAME = "csrftoken";
const CSRF_HEADER_NAME = "X-CSRFToken";

import { SettingService } from "./../services/settings";

const session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  withCredentials: true,
});

const handleHttpError = (statusCode) => {
  if (statusCode === 401) {
    let loginUrl = SettingService.settingsServiceBaseUrl + "/login";
    window.location.href = loginUrl;
  }
};

session.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode) {
        handleHttpError(statusCode);
      }
    }
    return Promise.reject(error);
  }
);

export default session;
