<template>
  <div class="appListPage">
    <!-- Header -->
    <div class="px-8 pt-5 pb-4 border-b pageHeader">
      <div class="text-5xl font-bold title">App Integrations</div>
      <p class="mt-2.5 leading-4 text-sm text-gray-900">Extend Growlytics functionalities by integrating your existing tools with Growlytics.</p>
    </div>

    <!-- Loader -->
    <div class="flex items-center justify-center w-full h-screen" v-if="fetchingDetails">
      <q-spinner class="" color="primary" size="3em" :thickness="2" />
    </div>

    <template v-else>
      <div class="m-10 mb-8" v-for="(categoryAppList, categoryName) in appList" :key="categoryName">
        <!-- Category Header -->
        <div class="text-lg font-semibold mb-2 capitalize">{{ categoryName }}</div>
        <div class="grid grid-cols-3 gap-4">
          <!-- App Card -->
          <template v-for="(app, index) in categoryAppList" :key="index">
            <router-link :to="app.meta && app.meta.comingsoon ? '' : '/app/' + app.id">
              <div class="static bg-white hover:bg-gray-50 cursor-pointer border p-4 rounded-lg border-gray-300 relative hover:shadow-md">
                <img class="h-16 rounded-md" :src="app.logo" alt="" />
                <div class="text-base font-semibold mt-2 mb-1">{{ app.name }}</div>
                <div class="text-gray-600" style="font-size: 13px; line-height: 18px">{{ app.short_description }}</div>

                <!-- Installed Icon -->
                <div class="absolute top-3 right-3 text-2xl" v-if="app.installed">
                  <div class="bg-green-100 text-green-600 text-xs py-1 px-2 rounded"><i class="fa-sharp fa-solid fa-circle-check text-green-700"></i> Installed</div>
                  <q-tooltip class="bg-black text-white"> App Installed </q-tooltip>
                </div>

                <!-- Coming Soon Icon -->
                <div class="absolute top-3 right-3 text-gray-600 bg-gray-200 px-2 border py-1 rounded font-semibold text-xs" v-if="app.meta && app.meta.comingsoon">Coming Soon</div>
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { IntegrationSettingsMeta } from "@/services/integrationSettingsMeta";
import { AppService } from "../services/appService";

export default {
  data() {
    return {
      fetchingDetails: true,
      pageError: false,
      appList: null,
      categoryList: null,
    };
  },

  methods: {
    async fetchAppList() {
      try {
        this.fetchingDetails = true;

        let response = await AppService.getAppList();
        this.appList = response.data.appList;
        for (let category in this.appList) {
          for (let app of this.appList[category]) {
            app.meta = IntegrationSettingsMeta.getMeta(app.id);
          }
        }
        this.categoryList = response.data.categoryList;

        console.log(this.appList);
      } catch (err) {
        console.log(err);
      } finally {
        this.fetchingDetails = false;
      }
    },
  },

  mounted() {
    this.fetchAppList();
  },
};
</script>

<style lang="scss">
.appListPage {
  .pageHeader {
    background-color: #ffffff;
    opacity: 0.8;
    background: linear-gradient(135deg, #f4f4f455 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #f4f4f4 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #f4f4f455 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #f4f4f4 25%, #ffffff 25%) 0px 0/ 20px 20px;

    .title {
      color: #002225;
    }
  }
}
</style>
