import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  methods: {
    notifySuccess(message) {
      Notify.create({
        message: message,
        color: "green-8",
        position: "top",
        actions: [
          {
            label: "Dismiss",
            color: "white",
          },
        ],
      });
    },

    notifyError(message) {
      Notify.create({
        message: message,
        color: "red-8",
        position: "top",
        actions: [
          {
            label: "Dismiss",
            color: "white",
          },
        ],
      });
    },

    getAuthToken() {
      return this.$store.state.token;
    },
  },
};
