import AppInstallPopup from "@/components/appInstallPopup/appInstallPopup.vue";
import { AppService } from "../../services/appService";
import { IntegrationSettingsMeta } from "../../services/integrationSettingsMeta";

export default {
  name: "AppDetail",
  components: {
    AppInstallPopup,
  },

  data() {
    return {
      fetchingDetails: true,
      uninstallAppInProgress: false,
      installInProgress: false,
      app: null,
      appId: null,
      slide: "1",

      // Share Api Key Popup Variables
      shareApiKeyPopup: {
        visible: false,
        apiKey: null,
      },
    };
  },

  methods: {
    async fetchApp() {
      try {
        this.fetchingDetails = true;
        let response = await AppService.getAppDetails(this.appId);
        this.app = response.data;

        // Attach Meta Data
        this.app.meta = IntegrationSettingsMeta.getMeta(this.app.id);

        // Parepare intallation link for oauth apps
        if (this.app.meta.installType === "oauth") {
          this.app.meta.installLink = this.app.meta.getInstallLink(this.app, this);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.fetchingDetails = false;
      }
    },

    async onAppInstall() {
      if (this.app.meta.installType === "oauth") {
        let url = this.app.meta.installLink;
        window.open(url, "_blank");
      } else if (this.app.meta.installType === "reverseIntegration") {
        try {
          this.installInProgress = true;
          // Mark App as installed
          let response = await AppService.installApp({
            appId: this.app.id,
            installType: this.app.meta.installType,
            settings: JSON.stringify({}),
          });

          this.app.installed = true;

          // Show Share Api Key Popup
          this.shareApiKeyPopup.apiKey = response.data.apiKey;
          this.shareApiKeyPopup.visible = true;
        } catch (err) {
          console.log("App install failed", err);
        } finally {
          this.installInProgress = false;
        }
      } else {
        this.$refs.appInstallPopup.showPopup(this.app);
      }
    },

    async onAppInstallCallback() {
      this.app.installed = true;
    },

    async onAppUninstall() {
      this.uninstallAppInProgress = true;
      try {
        let payload = {
          appId: this.app.id,
        };

        await AppService.uninstallApp(payload);

        this.app.installed = false;
        this.notifySuccess("App uninstalled successfully.");
      } catch (err) {
        console.log(err);
      } finally {
        this.uninstallAppInProgress = false;
      }
    },
  },

  mounted() {
    this.appId = this.$route.params.appId;
    this.fetchApp();
  },
};
