import { AppService } from "../../services/appService";
import { IntegrationSettingsMeta } from "../../services/integrationSettingsMeta";
export default {
  name: "AppInstallPopup",
  data() {
    return {
      app: null,
      appMeta: null,
      visible: false,
      submittingForm: false,

      form: {},
    };
  },

  methods: {
    showPopup(app) {
      this.app = app;

      // Read Meta For Given App
      this.appMeta = IntegrationSettingsMeta.getMeta(app.id);
      console.log("App Meta", this.appMeta);

      // Iterate over meta fields and create form fields
      this.form = {};
      this.appMeta.fields.forEach((field) => {
        this.form[field.id] = null;
      });

      this.visible = true;
    },

    async onSubmit() {
      // Save Integration
      this.submittingForm = true;
      try {
        let payload = {
          settings: JSON.stringify(this.form),
          installType: "oauth",
          appId: this.app.id,
        };

        let response = await AppService.installApp(payload);

        if (!response.data.success) {
          this.notifyError(response.data.message);
          return;
        }

        this.notifySuccess("App installed successfully.");
        this.visible = false;
        this.$emit("onAppInstallCallback");
      } catch (err) {
        console.log(err);
      } finally {
        this.submittingForm = false;
      }
    },

    onReset() {
      console.log("onReset");
    },
  },
};
