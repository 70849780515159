import { AuthService } from "@/services/authService";
import { createStore } from "vuex";

export default createStore({
  state: {
    credentials: {
      apiKey: "",
      accountId: "",
      token: null,
    },
  },
  actions: {
    // async fetchCredentials({ commit }) {
    //   try {
    //     const { data } = await fetchApiCredentials();
    //     commit("SAVE_CREDENTIALS", data);
    //   } catch (err) {
    //     console.log(err);
    //     // Silence
    //   }
    // },
    async fetchToken({ commit }) {
      try {
        const { data } = await AuthService.getToken();
        console.log("Token Data", data);
        commit("SAVE_TOKEN", data);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mutations: {
    SAVE_CREDENTIALS(state, { apiKey, id }) {
      state.apiKey = apiKey;
      state.accountId = id;
    },
    SAVE_TOKEN(state, data) {
      state.token = data;
    },
  },
  modules: {},
});
