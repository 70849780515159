<template>
  <div class="appInstallWebhookPage">
    <!-- Header -->
    <div class="px-8 pt-5 pb-4 border-b common-app-header">
      <div class="text-5xl font-bold title">App Integrations</div>
      <p class="mt-2.5 leading-4 text-sm text-gray-900">Extend Growlytics functionalities by integrating your existing tools with Growlytics.</p>
    </div>

    <!-- Loader -->
    <div class="flex items-center justify-center w-full h-screen" v-if="fetchingDetails">
      <q-spinner class="" color="primary" size="3em" :thickness="2" />
    </div>

    <template v-else>
      <div class="border bg-white rounded-md overflow-hidden p-3 m-auto mt-10 py-10" style="width: 600px">
        <!-- Logos -->
        <div class="flex justify-center">
          <div>
            <q-img class="rounded-lg" :src="app.logo" spinner-color="white" style="width: 100px" />
            <div class="w-full text-center pt-2">{{ app.name }}</div>
          </div>
          <div class="text-4xl px-8 flex justify-center items-center">
            <i class="fa-solid fa-arrows-repeat"></i>
          </div>
          <div>
            <q-img class="rounded-lg" src="/images/logo-square-dark.png" spinner-color="white" style="width: 100px" />
            <div class="w-full text-center pt-2">Growlytics</div>
          </div>
        </div>

        <!-- Loader -->
        <div class="text-center w-full mt-32 mb-10" v-if="integrationInProgress">
          <q-spinner-oval color="orange" size="5.5em" class="m-auto" />
          <div class="pt-6 text-gray-600">Install In Progress, please don't refresh the page.</div>
        </div>

        <!-- Error Message -->
        <div class="text-center pt-40 pb-20" v-if="installationError">
          <i class="fa-sharp fa-solid fa-circle-exclamation text-6xl text-red-600"></i>
          <div class="pt-3 leading-5 font-semibold">
            <div class="text-red-600 text-lg">Failed to verify the integration.</div>
            Please contact Growlytics support if you are seeing this message.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import appInstallWebhookComponent from "./appInstallWebhookComponent";
export default appInstallWebhookComponent;
</script>

<style lang="scss">
.appInstallWebhookPage {
  display: block;
}
</style>
