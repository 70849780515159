<template>
  <div class="appDetailPage">
    <!-- Header -->
    <div class="px-8 pt-5 pb-4 border-b pageHeader">
      <div class="text-5xl font-bold title">App Integrations</div>
      <p class="mt-2.5 leading-4 text-sm text-gray-900">Extend Growlytics functionalities by integrating your existing tools with Growlytics.</p>
    </div>

    <!-- Loader -->
    <div class="flex items-center justify-center w-full h-screen" v-if="fetchingDetails">
      <q-spinner class="" color="primary" size="3em" :thickness="2" />
    </div>

    <div v-else>
      <div class="">
        <div class="my-2 flex">
          <router-link to="/">
            <div class="py-3 pl-10 font-semibold">
              <i class="fa-regular fa-arrow-left-long"></i>
              &nbsp;
              <span>All Apps</span>
            </div>
          </router-link>
        </div>

        <!-- App Header  -->
        <div class="mx-10 p-5 border flex rounded-md bg-white">
          <!-- Left Side - Image -->
          <div>
            <div>
              <q-img class="rounded-lg" :src="app.logo" spinner-color="white" style="width: 120px" />
            </div>
          </div>

          <!-- Right Side - Text -->
          <div class="pl-6">
            <div class="mt-1 text-2xl font-semibold">
              {{ app.name }}
            </div>
            <div class="text-base">{{ app.short_description }}</div>
            <div class="mt-4">
              <q-btn v-if="!app.installed" class="bg-green text-white" @click="onAppInstall" :loading="installInProgress" unelevated label="Install App" />
              <q-btn v-else class="bg-secondary text-white" @click="onAppUninstall" :loading="uninstallAppInProgress" unelevated label="Uninstall App" />

              <a class="ml-4" :href="app.url" target="_blank"><q-btn class="text-gray" outline unelevated> Visit App Website </q-btn></a>
              <a class="ml-4" :href="app.url" target="_blank"><q-btn class="text-blue-700" outline unelevated> Learn more about integration </q-btn></a>
            </div>
          </div>
        </div>
      </div>

      <!-- Long Header -->
      <div class="flex gap-5 w-full px-10 py-5">
        <div class="flex-1 border rounded-md overflow-hidden bg-white">
          <!-- Header -->
          <div flat bordered>
            <div class="text-lg bg-gray-100 text-primary-500 font-semibold p-3 border-b">About {{ app.name }}</div>

            <!-- Long Description -->
            <div class="p-5">
              <div class="leading-6 text-gray-800" style="font-size: 14px">Judge.me is a product review collection tool that helps boost sales and grow your online store. With the Judge.me x Growlytics integration you can collect and sync reviews on autopilot. Create your own automation flows and engage with customers who have given reviews on your website. Make segments based on the Judge.me review event trigger and apply the following filters to further boost your sales through the various channels available on Growlytics.</div>
              <div>
                <img class="my-12" v-for="index in [1, 2, 3]" :key="index" :src="`https://static.growlytics.in/dashboard-assets/integration-panel/vendors/judgeme/${index}.png`" />
              </div>
            </div>
          </div>
        </div>

        <!-- Right Side - Use Cases -->
        <div class="border-y rounded-md overflow-hidden" style="width: 350px">
          <div class="text-lg border-x bg-gray-100 text-primary-500 font-semibold p-3 border-b">Use Cases</div>
          <div class="bg-white">
            <div v-for="(usecase, index) in app.usecase" :key="index" class="w-full border-b border-x">
              <div class="inline-block px-2.5 py-2 mr-2 mb-2">
                <div class="text-sm font-semibold mb-1">{{ usecase.title }}</div>
                <div class="text-gray-600" style="font-size: 13px; line-height: 19px">{{ usecase.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppInstallPopup ref="appInstallPopup" @onAppInstallCallback="onAppInstallCallback" />
    </div>

    <!-- Share Api Key Dialog -->
    <q-dialog v-model="shareApiKeyPopup.visible">
      <q-card style="width: 900px">
        <q-card-section class="mt-10 text-center">
          <i class="text-8xl fa-duotone fa-circle-check" style="--fa-primary-color: #54ac0c; --fa-secondary-color: #85ec32"></i>
          <div class="text-2xl font-semibold text-green-600">App Installed</div>
        </q-card-section>

        <q-card-section>
          <div class="mt-2 mb-5 font-semibold text-center" v-if="app">Share below Api Key in {{ app.name }} dashboard to finish integration.</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="w-full">
            <div class="bg-gray-50 p-3 border w-full border-gray-400 rounded-md text-center">
              <div class="text-sm text-gray-600">Growlytics Api Key</div>
              <div class="mt-1 text-lg font-semibold">{{ shareApiKeyPopup.apiKey }}</div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary pr-4 pb-4">
          <q-btn flat class="bg-gray-100 text-gray-900" label="Close" v-close-popup @click="showShareApiKeyPopup = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import appDetailComponent from "./appDetailComponent";
export default appDetailComponent;
</script>

<style lang="scss">
.appDetailPage {
  .pageHeader {
    background-color: #ffffff;
    opacity: 0.8;
    background: linear-gradient(135deg, #f4f4f455 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #f4f4f4 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #f4f4f455 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #f4f4f4 25%, #ffffff 25%) 0px 0/ 20px 20px;

    .title {
      color: #002225;
    }
  }

  .appSection {
    max-width: 1200px;
    margin: auto;
  }

  .q-carousel {
    .q-btn {
      background: black !important;
    }

    .q-carousel__navigation {
      display: none;
      .q-btn {
        font-size: 10px !important;
      }
    }
  }

  .selectedSliderButton {
    background: #002225 !important;
    color: white !important;
  }
}
</style>
