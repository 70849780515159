import AppInstallPopup from "@/components/appInstallPopup/appInstallPopup.vue";
import { AppService } from "../../services/appService";

export default {
  name: "AppInstallWebhook",
  components: {
    AppInstallPopup,
  },

  data() {
    return {
      fetchingDetails: true,
      integrationInProgress: true,
      installationError: false,
      app: false,
    };
  },

  methods: {
    async fetchAppDetails() {
      try {
        this.fetchingDetails = true;
        let response = await AppService.getAppDetails(this.appId);
        this.app = response.data;
      } catch (err) {
        console.log(err);
      }
      this.fetchingDetails = false;
    },

    async handleInstall() {
      try {
        this.integrationInProgress = true;
        let state = JSON.parse(decodeURIComponent(this.$route.query.state));
        console.log("State", state);

        let code = this.$route.query.code;
        console.log("Code", code);

        let payload = {
          appId: this.$route.params.appId,
          queryParams: this.$route.query,
        };

        let response = await AppService.handleOauthRedirect(payload);

        if (!response.data.success) {
          this.installationError = true;
          return;
        }

        this.notifySuccess("App Installed Successfully.");
        this.$router.push("/app/" + this.appId);
      } catch (err) {
        console.log(err);
        this.installationError = true;
      } finally {
        this.integrationInProgress = false;
      }
    },
  },

  mounted() {
    this.appId = this.$route.params.appId;

    this.fetchAppDetails();
    this.handleInstall();
  },
};
