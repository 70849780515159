<template>
  <q-dialog v-model="visible" noBackdropDismiss>
    <q-card class="my-card rounded-lg" style="width: 700px">
      <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md">
        <div class="px-3 py-3 items-center border-b">
          <div class="text-lg">Install - {{ app.name }}</div>
        </div>
        <div>
          <div v-for="(field, index) in appMeta.fields" :key="index" class="q-pa-md q-gutter-sm pt-3">
            <div class="text-xs text-gray-600">{{ field.label }}</div>
            <q-input :ref="'input-' + field.id" outlined v-model="form[field.id]" :placeholder="field.placeholder" :dense="true" :rules="field.validationRules" />
          </div>
        </div>

        <div class="px-3 py-2 items-center border-t text-right">
          <q-btn label="Install" :loading="submittingForm" unelevated type="submit" color="primary" />
          <q-btn outline color="secondary" v-close-popup class="ml-3" label="Cancel" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import appInstallPopupComponent from "./appInstallPopupComponent";
export default appInstallPopupComponent;
</script>
